<template>
<div>
  <v-row>
      <v-col cols="4">
                      <v-select
                        :items="['Date','Template Name','Phone']"
                        label="Filters"
                        outlined
                        dense
                        multiple
                        required
                        chips
                        v-model="filterItems"
                        prepend-icon=""
                         append-icon="mdi-filter"
                      ></v-select>
         </v-col>
        <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        type="date"
                        v-show="filterItems.includes('Date')"
                        v-model="filterDate"
                        required
                        label="Date"
                      ></v-text-field>
                    </v-col>
       <v-col cols="4">
                      <v-text-field
                        outlined
                        dense
                        v-model="filterByPackageName"
                        v-show="filterItems.includes( 'Template Name')"
                        required
                        label="Template Name"
                        append-icon="mdi-rename-box"
                      ></v-text-field>
                    </v-col>
        <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      v-show="filterItems.includes('Phone')"
                      v-model="filterByPhone"
                     
                      required
                      label="Mobile Number"
                      append-icon="mdi-package"
                    ></v-text-field>
                  </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filterFinalData"
      sort-by="calories"
      class="elevation-3"
    >
      <template v-slot:no-data>
        <v-progress-circular
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-circular>
        <v-icon
           v-else
           x-large
           color="grey lighten-1"
           >
           mdi-tray-remove
        </v-icon>
  
      </template>
    </v-data-table>
</div>
</template>
<script>
import * as moment from 'moment'
export default {
  name: 'Templates',
  data: () => ({
    headers: [
      {
        text: 'Purchase ID',
        value: 'Order_Id',
      },
      { text: 'Customer Name', value: 'Customer_Name' },
      { text: 'Customer Phone', value: 'Customer_Phone' },
      { text: 'Template Name', value: 'Template_Name' },
      { text: 'Template Amount', value: 'Template_Amount' },
      { text: 'Final Amount', value: 'Final_Amount' },
      { text: 'Date', value: 'Transaction_date' },
      { text: 'Status', value: 'Status' },
    ],
    data: [],
    filterItems:[],
    filterByPackageName:'',
    filterDate:'',
    filterByPhone:''
  }),
  created() {
    this.getPackages();
  },
  methods: {
    //Packages API Calls
    getPackages() {
      this.loading = true
      this.$Axios
        .post('template-purchase-details')
        .then(({data}) => {
          this.data = data.TemplatePurchaseDetails;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
           this.loading = false
        })
    },
  },
   computed:{
      filterFinalData(){
        if(this.filterItems.length === 0){
          return this.data
        }else{
    //   //moment(this.editedItem.date).format('YYYY-MM_DD')
            let data = this.data
            if(this.filterItems.includes('Date') && this.filterDate){
              data = data.filter((data) =>  data.Transaction_date === moment(this.filterDate).format('YYYY-MM-DD'))
            }
            if(this.filterItems.includes('Template Name') && this.filterByPackageName ){
             data= data.filter((data) =>  data.Template_Name?.toLowerCase()?.includes(this.filterByPackageName.toLowerCase()))
            }
             if(this.filterItems.includes('Phone') && this.filterByPhone ){
             data= data.filter((data) =>  data.Customer_Phone?.toLowerCase()?.includes(this.filterByPhone.toLowerCase()))
            }
            return data
        }
      }
    
   }
};
</script>