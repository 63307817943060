<template>
<div>
  <v-row>
    <v-col cols="4">
                    <v-select
                      :items="['Date','Package Name','Phone']"
                      label="Filters"
                      outlined
                      dense
                      multiple
                      required
                      chips
                      v-model="filterItems"
                      prepend-icon=""
                      append-icon="mdi-filter"
                    ></v-select>
       </v-col>
      <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      type="date"
                      v-show="filterItems.includes('Date')"
                      v-model="filterDate"
                      required
                      label="Date"
                    ></v-text-field>
                  </v-col>
     <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="filterByPackageName"
                      v-show="filterItems.includes( 'Package Name')"
                      required
                      label="Package Name"
                      append-icon="mdi-package"
                    ></v-text-field>
                  </v-col>
       <v-col cols="4">
                    <v-text-field
                      outlined
                      dense
                      v-show="filterItems.includes('Phone')"
                      v-model="filterByPhone"
                     
                      required
                      label="Mobile Number"
                      append-icon="mdi-package"
                    ></v-text-field>
                  </v-col>
  </v-row>
  <v-data-table
    :headers="headers"
    :items="filterFinalData"
    sort-by="calories"
    class="elevation-3"
    :search="search"

  >
    <template v-slot:no-data>
      <v-progress-circular
      v-if="loading"
      indeterminate
      color="secondary"
    ></v-progress-circular>
      <v-icon
         v-else
         x-large
         color="grey lighten-1"
         >
         mdi-tray-remove
      </v-icon>
      
    </template>
  </v-data-table>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import * as moment from 'moment'
export default {
  name: 'Packages',
  mixins: [validationMixin],
  data: () => ({
    selectedPackage: '',
    transactionList: [],
    editedIndex: -1,
    search:'',
    editedItem: {
      filters: [],
      date:null,
      packageName:null
      
    },
    filterItems:[],
    filterByPackageName:'',
    filterDate:'',
    filterByPhone:'',
    defaultItem: {
      filters: [],
      date:null,
      packageName:null
      
    },
    headers: [
      {
        text: 'Purchase ID',
        value: 'Order_Id',
      },
      { text: 'Customer Name', value: 'Customer_Name' },
      { text: 'Customer Phone', value: 'Customer_Phone' },
      { text: 'Package Name', value: 'Package_Name' },
      { text: 'Credits', value: 'Package_Credits'},
       { text: 'Package Amount', value: 'Package_Amount' },
      { text: 'Promocode Amount', value: 'Promocode_Amount' },
      { text: 'Final Amount', value: 'Final_Amount' },
      { text: 'Date', value: 'Transaction_date' },
      { text: 'Status', value: 'Status' },
    ],
    data: [],
   
  }),
  created() {
    this.getPackages();
    //this.filterPackages();
  
  },
  methods: {
    //Packages API Calls
    getPackages() {
      this.loading = true
      this.$Axios
        .post('package-purchase-details')
        .then(({data}) => {
          this.data = data.PackagePurchaseDetails;
          
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
           this.loading = false
        })
    },
    //API for filter
    // filterPackages() {
    //   //tempdate: (value) => { return moment(value, 'YYYY-MM-DD', true).isValid()}
    //   this.loading = true
    //   //moment(this.editedItem.date).format('YYYY-MM_DD')
    //   this.$Axios
    //     .post(`filter-transcations?transactionID=1&date=${this.editedItem.date}&packageName=${this.editedItem.packageName}`)
    //     .then(({data}) => {
    //       console.log(this.editedItem.date)
    //       console.log(this.editedItem.packageName)
    //       console.log(data.Details)
    //       this.data = data.Details;
          
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     })
    //     .finally(() => {
    //        this.loading = false
    //     })
    // },
    
  },
   computed:{
      filterFinalData(){
        if(this.filterItems.length === 0){
          return this.data
        }else{
    //   //moment(this.editedItem.date).format('YYYY-MM_DD')
            let data = this.data
            if(this.filterItems.includes('Date') && this.filterDate){
              data = data.filter((data) =>  data.Transaction_date === moment(this.filterDate).format('YYYY-MM-DD'))
            }
            if(this.filterItems.includes('Package Name') && this.filterByPackageName ){
             data= data.filter((data) =>  data.Package_Name?.toLowerCase()?.includes(this.filterByPackageName.toLowerCase()))
            }
            if(this.filterItems.includes('Phone') && this.filterByPhone ){
             data= data.filter((data) =>  data.Customer_Phone?.toLowerCase()?.includes(this.filterByPhone.toLowerCase()))
            }
            return data
        }
      }
    
   }
  
};
</script>